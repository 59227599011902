import React from "react"
import ReactDOM from "react-dom/client"
// import "bulma/css/bulma.min.css"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"

const GraphQL_URI =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_GraphQL_URI_DEV
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_GraphQL_URI_PROD
    : null
if (!GraphQL_URI) {
  throw "GraphQL_URI Invalid"
}
console.log("GraphQL_URI:", GraphQL_URI)
const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: GraphQL_URI,
})
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <div className="dockview-theme-abyss">
        <App />
      </div>
    </React.StrictMode>
  </ApolloProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
