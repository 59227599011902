import React, { useEffect, useLayoutEffect, useRef, useState } from "react"

import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets"

interface TradingViewWidgetProps {
  symbol: string
  height: any
  width: any
}

export const TradingViewWidget = (props: TradingViewWidgetProps) => {
  return (
    <div className="flex justify-center">
      <AdvancedRealTimeChart
        symbol={props.symbol}
        theme="dark"
        height={props.height}
        width={props.width}
      ></AdvancedRealTimeChart>
    </div>
  )
}
