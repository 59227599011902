import DockLayout, { DockMode, LayoutBase, LayoutData } from "rc-dock"
import { useEffect, useRef, useState } from "react"
import { HistoryOrders } from "./components/HistoryOrders"
import { MyHoldings } from "./components/MyHoldings"
import { OnGoingOrders } from "./components/OnGoingOrders"
import { PlaceOrder } from "./components/PlaceOrder"
import { TradingViewWidget } from "./components/Tradingview"

const defaultLayout = {
  dockbox: {
    mode: "vertical" as DockMode,
    children: [
      {
        tabs: [
          {
            id: "TradingView",
            title: "Trading View",
            content: (
              <div>
                <TradingViewWidget
                  symbol={"BINANCE:ETHUSDT"}
                  height={"500"}
                  width={"full"}
                ></TradingViewWidget>
              </div>
            ),
          },
        ],
      },
      {
        tabs: [
          {
            id: "PlaceOrder",
            title: "下单",
            content: (
              <div>
                <PlaceOrder></PlaceOrder>
              </div>
            ),
          },
          {
            id: "OnGoingOrders",
            title: "进行订单",
            content: (
              <div>
                <OnGoingOrders></OnGoingOrders>
              </div>
            ),
          },
          {
            id: "HistoryOrders",
            title: "历史订单",
            content: (
              <div>
                <HistoryOrders></HistoryOrders>
              </div>
            ),
          },
          {
            id: "MyHoldings",
            title: "我的持仓",
            content: (
              <div>
                <MyHoldings></MyHoldings>
              </div>
            ),
          },
        ],
      },
    ],
  },
}

export function Dock() {
  let [dockLayout, setDockLayout] = useState<DockLayout>()
  const saveLayout = (newLayout: LayoutData) => {
    if (dockLayout !== undefined) {
      // console.log("SAVE", newLayout)
      localStorage.setItem("LAYOUT", JSON.stringify(newLayout))
    }
  }
  const getRef = (r: DockLayout) => {
    // console.log("SET", r)
    if (r !== null && r !== undefined) {
      setDockLayout(r)
    }
  }
  useEffect(() => {
    const previousLayout = JSON.parse(localStorage.getItem("LAYOUT")!)
    if (
      localStorage.getItem("LAYOUT") !== undefined &&
      localStorage.getItem("LAYOUT") !== null &&
      previousLayout &&
      dockLayout !== undefined
    ) {
      console.log("LOAD", previousLayout)
      dockLayout?.loadLayout(
        JSON.parse(localStorage.getItem("LAYOUT")!) as unknown as LayoutBase
      )
    }
  }, [dockLayout])

  return (
    <DockLayout
      defaultLayout={defaultLayout}
      dropMode="edge"
      ref={getRef}
      onLayoutChange={saveLayout}
      style={{
        position: "absolute",
        left: 0,
        top: 52,
        right: 0,
        bottom: 0,
      }}
    />
  )
}
