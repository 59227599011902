import { Fragment, useContext, useEffect, useReducer, useState } from "react"
import { TrendingUpIcon, UserIcon } from "@heroicons/react/outline"
import { Button, Navbar } from "react-bulma-components"
import { AppContext, ExchangeList, ModeList } from "../App"

export function HeaderNav() {
  const context = useContext(AppContext)
  return (
    <div>
      <Navbar className="px-4 is-primary">
        <Navbar.Brand>
          <Navbar.Item href="#">
            <TrendingUpIcon
              className=""
              height={28}
              width={28}
            ></TrendingUpIcon>
          </Navbar.Item>
          <Navbar.Item href="#">
            <p className="subtitle">Trading Dashboard</p>
          </Navbar.Item>
          <Navbar.Burger />
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Container>
            <div className="navbar-item has-dropdown is-hoverable" key="Mode">
              <p className="navbar-link">{ModeList[context.Mode].name}</p>
              <div className="navbar-dropdown">
                {ModeList.map((value, i) => {
                  return (
                    <p
                      className="navbar-item"
                      onClick={() => {
                        context.setMode(i)
                      }}
                      key={i}
                    >
                      {value.name}
                    </p>
                  )
                })}
              </div>
            </div>
            {ModeList[context.Mode].Sub !== null ? (
              <div
                className="navbar-item has-dropdown is-hoverable"
                key="Exchange"
              >
                <p className="navbar-link">
                  {ExchangeList[context.Exchange].displayName}
                </p>
                <div className="navbar-dropdown">
                  {ExchangeList.map((value, i) => {
                    return (
                      <p
                        className="navbar-item"
                        onClick={() => {
                          context.setExchange(i)
                        }}
                        key={i}
                      >
                        {value.displayName}
                      </p>
                    )
                  })}
                </div>
              </div>
            ) : null}

            {ModeList[context.Mode].Sub !== null ? (
              <div
                className="navbar-item has-dropdown is-hoverable"
                key="Market"
              >
                <p className="navbar-link">
                  {ExchangeList[context.Exchange].Markets[context.Market]}
                </p>
                <div className="navbar-dropdown">
                  {ExchangeList[context.Exchange].Markets.map((value, i) => {
                    return (
                      <p
                        className="navbar-item"
                        onClick={() => {
                          context.setMarket(i)
                        }}
                        key={i}
                      >
                        {value}
                      </p>
                    )
                  })}
                </div>
              </div>
            ) : null}
          </Navbar.Container>
          <Navbar.Container align="right">
            <div className="navbar-item has-dropdown is-hoverable">
              <p className="navbar-link">
                <UserIcon height={20} width={20}></UserIcon>
              </p>
              <div className="navbar-dropdown is-right">
                <p className="navbar-item">My Accounts</p>
                <p className="navbar-item">About</p>
                <p className="navbar-item">Report an issue</p>
              </div>
            </div>
          </Navbar.Container>
        </Navbar.Menu>
      </Navbar>
    </div>
  )
}
