import React, {
  Context,
  useReducer,
  useState,
  useSyncExternalStore,
} from "react"
import { Button } from "react-bulma-components"
import { HistoryOrders } from "./components/HistoryOrders"
import { MyHoldings } from "./components/MyHoldings"
import { OnGoingOrders } from "./components/OnGoingOrders"
import { PlaceOrder } from "./components/PlaceOrder"

import { HeaderNav } from "./layout/Header"
import "rc-dock/dist/rc-dock-dark.css"
import DockLayout, { DockMode } from "rc-dock"
import { Dock } from "./Dock"
import { createContext } from "react"

export const ExchangeList = [
  { name: "BINANCE", displayName: "Binance", Markets: ["Spot", "Futures"] },
  { name: "OKX", displayName: "Okx Exchange", Markets: ["Spot", "Futures"] },
] as const

export const ModeList = [
  { name: "Trade", Sub: ExchangeList },
  {
    name: "Aggregate",
    Sub: null,
  },
]

export const AppContext = createContext({
  Mode: 0,
  setMode: (e: any) => {},
  Exchange: 0,
  setExchange: (e: any) => {},
  Market: 0,
  setMarket: (e: any) => {},
})

function App() {
  const [Mode, setMode] = useState(0)
  const [Exchange, setExchange] = useState(0)
  const [Market, setMarket] = useState(0)

  return (
    <AppContext.Provider
      value={{
        Mode: Mode,
        setMode: setMode,
        Exchange: Exchange,
        setExchange: setExchange as any,
        Market: Market,
        setMarket: setMarket as any,
      }}
    >
      <div className="App">
        <HeaderNav></HeaderNav>
        <Dock></Dock>
      </div>
    </AppContext.Provider>
  )
}

export default App
